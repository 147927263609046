<!--
  Created by Lingjun Meng on 26/6/2022
-->
<template>
  <div>
    <!--    电脑端   -->
    <div class="hidden-sm-and-down">
      <!-- 轮播图 Large -->
      <el-carousel height="29rem" indicator-position="none">
        <el-carousel-item
          v-for="(item, index) in bannerDesktopList"
          :key="index"
        >
          <el-image
            style="min-height: 20rem"
            :src="item"
            @click.native="jumpToProductList"
            arrow="always"
            fit="scale-down"
          >
          </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 轮播图 Medium -->
    <div class="hidden-md-and-up">
      <div class="hidden-xs-only">
        <el-carousel height="25rem">
          <el-carousel-item
            v-for="(item, index) in bannerPhoneList"
            :key="index"
          >
            <el-image
              style="min-height: 20rem"
              :src="item"
              @click.native="jumpToProductList"
              arrow="always"
              fit="scale-down"
            >
            </el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="hidden-xs-only">
      <div
        class="home-youtube-player"
        position="relative"
        z-index="-1"
        style="margin-top: -3%"
      >
        <video class="home-desktop-video" controls>
          <source src="/static/img/kinns_video.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="home-desktop-company-intro">
        <h1>Helping People Enjoy a Better Life</h1>

        <p style="font-size: 18px">
          Kings Milk Pty Ltd is the sole brand owner and supplier of the
          renowned ‘KINNS DAIRY’ for dairy products in Australia. Our products
          are all 100% made in Australia. Being certified by many international
          foods and health organizations, ‘KINNS’ products meet the highest
          international and Australian health and quality standards. The
          manufacturing process of our products is TGA approved by the
          Department of Health of the Australian Government.
        </p>
      </div>

      <div class="home-desktop-company-intro">
        <h1>Continuing the Australian Tradition</h1>

        <p style="font-size: 18px">
          As an Australian owned company, we source quality Australian produce
          and proudly make our products locally in Melbourne, Victoria. Our Milk
          is 100% Australian, sourced from happy grass-fed free-range cows in
          Victoria – an area ideally suited to be dairy farming due to its
          reliable rainfall, fertile soil and temperate climate.
        </p>
      </div>
      

      <!-- product batches image -->
      <div class="demo-image__lazy">
        <!-- click image scroll to product batches tracking contents -->
        <el-image
          style="width: 60%;"
          v-for="url in urls"
          :key="url"
          :src="url"
          lazy
        ></el-image>
      </div>

      <!-- 产品轮播 电脑端 Large -->
      <div class="hidden-sm-and-down">
        <div style="margin: 0 auto; width: 60%">
          <el-carousel height="65.625rem" indicator-position="none">
            <el-carousel-item v-for="(item, index) in products" :key="index">
              <div style="background-color: white">
                <el-image style="" :src="item.imgUrl" fit="contain"></el-image>

                <el-button
                  type="success"
                  @click.native="jumpToProductList"
                  round
                  >Learn More
                </el-button>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <!-- 产品轮播 电脑端 Medium -->
      <div class="hidden-md-and-up">
        <div class="hidden-xs-only">
          <div style="margin: 0 auto; width: 60%">
            <el-carousel height="34.375rem" indicator-position="none">
              <el-carousel-item v-for="(item, index) in products" :key="index">
                <div style="background-color: white">
                  <el-image
                    style=""
                    :src="item.imgUrl"
                    fit="contain"
                  ></el-image>

                  <el-button
                    type="success"
                    @click.native="jumpToProductList"
                    round
                    >Learn More
                  </el-button>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>

    <!--    手机端    -->
    <div class="hidden-sm-and-up">
      <div class="home-carousel">
        <el-carousel height="250px">
          <el-carousel-item
            v-for="(item, index) in bannerPhoneList"
            :key="index"
          >
            <el-image
              style="min-height: 200px"
              :src="item"
              @click.native="jumpToProductList"
              fit="cover"
            >
            </el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="home-youtube-player">
        <video
          poster="/static/img/home_video_cover.png"
          style="width: 100%"
          controls
        >
          <source src="/static/img/kinns_video.mp4" type="video/mp4" />
        </video>
      </div>

      <div class="company-intro">
        <!--        <el-button @click="changeLanguage('zh')">Change Language</el-button>-->
        <h3>{{ $tc("home.title1") }}</h3>

        <p>
          Kings Milk Pty Ltd is the sole brand owner and supplier of the
          renowned ‘KINNS DAIRY’ for dairy products in Australia. Our products
          are all 100% made in Australia. Being certified by many international
          foods and health organizations, ‘KINNS’ products meet the highest
          international and Australian health and quality standards. The
          manufacturing process of our products is TGA approved by the
          Department of Health of the Australian Government.
        </p>
      </div>
      <div class="company-intro">
        <h3>Continuing the Australian Tradition</h3>

        <p>
          As an Australian owned company, we source quality Australian produce
          and proudly make our products locally in Melbourne, Victoria. Our Milk
          is 100% Australian, sourced from happy grass-fed free-range cows in
          Victoria – an area ideally suited to be dairy farming due to its
          reliable rainfall, fertile soil and temperate climate.
        </p>
      </div>

      <!-- Production Process Timeline Image  -->

      <!-- product batches image -->
      <div class="demo-image__lazy">
        <!-- click image scroll to product batches tracking contents -->
        <el-image
          v-for="url in urls"
          style="width: 100%;"
          :key="url"
          :src="url"
          lazy
        ></el-image>
      </div>

      <el-carousel height="450px" indicator-position="outside">
        <el-carousel-item v-for="(item, index) in products" :key="index">
          <div style="background-color: white; height: 500px">
            <el-image
              style="width: 100%"
              :src="item.imgUrl"
              fit="contain"></el-image>

            <el-button type="success" @click.native="jumpToProductList" round>Learn More
            </el-button>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import products from "@/assets/products";
import production_url from "@/assets/img/production.jpeg";

export default {
  name: "index",
  data() {
    return {
      urls: [production_url], // production image
      videoId: "vPpwB9L1DqY",
      playerVars: {
        autoplay: 1,
      },
      products: products,
      bannerPhoneList: [
        "/static/img/banner_phone_05.jpeg",
        "/static/img/banner_phone_06.jpeg",
      ],
      bannerDesktopList: [
        "/static/img/banner_05.jpeg",
        "/static/img/banner_03.jpeg",
        "/static/img/banner_02.jpeg",
        "/static/img/banner_03.jpeg",
      ],
    };
  },
  methods: {
    playing() {
      console.log("\o/ we are watching!!!");
    },
    jumpToProductList() {
      this.$router.push("/product");
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang; // 设置给本地的i18n插件
      this.$message.success("切换多语言成功");
    },
  },
};
</script>

<style scoped>
.home-carousel {
  width: 112%;
  margin-left: -6%;
  margin-top: -3%;
}
.home-youtube-player {
  width: 100%;
}

.company-intro {
  text-align: justify;
}

.home-desktop-video {
  width: 60%;
  margin-top: 5%;
}

.home-desktop-company-intro {
  width: 60%;
  margin: auto;
  text-align: justify;
  padding-bottom: 5rem;
}

.home-desktop-company-intro p {
  color: #606266;
}
</style>
