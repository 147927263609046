<!--
  Created by Lingjun Meng on 29/6/2022
-->
<template>
  <div class="product-item">
    <img
      :src="productItem.imgUrl"
      class="product-img"
      @click="jumpToProductDetail()"
    />
    <h2>{{ productItem.productName }}</h2>
  
  <!-- 20/11/2023 根据需求， 将产品列表页面的产品价格删除， 并更改添加到购物车按钮为详情咨询按钮 -->

  <el-button
    type="success"
    style="
    /* color: #99b2d1;  */
    /* border: 1px #99b2d1 solid */
    font-size: 1rem;
    font-weight: bold;"

    @click="jumpToProductDetail()"
  >
    查看详情
  </el-button>

  <!-- 
    <p>{{ productItem.priceAUD }}</p>
    <el-button
      v-if="productNum === 0"
      round
      style="color: #99b2d1; border: 1px #99b2d1 solid"
      @click.native="addToCart"
      >ADD TO CART</el-button
    >
    <el-input-number
      class="product-input-number"
      v-if="productNum !== 0"
      v-model="productNum"
      @change="handleChange"
      :min="0"
      label="描述文字"
    ></el-input-number>

  -->

  </div>
</template>

<script>
import { getCart, updateCart } from "@/utils/store";

export default {
  name: "product",
  props: {
    productItem: {},
  },
  data() {
    return {
      productNum: 0,
    };
  },
  methods: {
    handleChange() {
      updateCart(this.productItem.productId, this.productNum);
      this.$emit("updateCart");
    },
    addToCart() {
      this.productNum++;
      updateCart(this.productItem.productId, this.productNum);
      this.$emit("updateCart");
    },
    initProductNum() {
      let cartList = getCart().split("-");
      this.productNum = cartList[this.productItem.productId];
    },
    jumpToProductDetail() {
      this.$router.push({
        path: `/productDetail/${this.productItem.productId}`,
      });
    },
    updateProductNum(param) {
      if (this.productItem.productId === param.productId) {
        this.productNum = param.num;
        updateCart(this.productItem.productId, this.productNum);
        this.$emit("updateCart");
      }
    },
  },
  created() {
    this.initProductNum();
  },
};
</script>

<style scoped>
.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  color: inherit;
  text-decoration: none;
  background-color: #fff;
  box-shadow: 0 20px 40px #0000001a;
  border-radius: 5px;
  text-align: center;
  transition: box-shadow 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  margin: 40px 0 40px 0;
  height: 29.1767rem;
}

.product-item .product-img {
  max-height: 300px;
}

.product-item .product-input-number {
  border-radius: 10px;
}

/* Add(+) or Remove(-) button */

.product-input-number .el-input-number__decrease {
  left: 1px;
  border-radius: 1000px 1000px 1000px 1000px;
  border-right: 1px solid #dcdfe6;
}
</style>
