<!--
  Created by Lingjun Meng on 29/6/2022
-->
<template>
  <div class="hidde">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      style="margin-top: 2%; padding-left: 20px"
    >
      <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/product' }"
        >Product List</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ product.productName }}</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- Mobile端 -->
    <div class="hidden-md-and-up">
      <div class="product-detail-img-and-add">
        <div class="product-detail-img">
          <el-image
            style="width: 16.668rem"
            :src="product.imgUrl"
            fit="contain"
          ></el-image>
        </div>
        <div class="product-detail-add-cart">
          <h2>{{ product.productName }}</h2>

        <!-- // 20/11/2023 根据需求，产品加入购物车按钮， 根据需求隐藏该按钮(并隐藏产品价格)， 替换为微信二维码，  -->
        <!-- 
          <p>{{ product.priceAUD }}</p>
          <el-button
            v-if="productNum === 0"
            round
            style="
              color: #99b2d1;
              border: 1px #99b2d1 solid;
              margin-bottom: 0.625rem;
            "
            @click.native="addToCart"
            >ADD TO CART</el-button
          >

          <el-input-number
            class="product-input-number"
            v-if="productNum !== 0"
            v-model="productNum"
            @change="handleChange"
            :min="0"
            :max="10"
            label="描述文字"
          ></el-input-number>
        -->

        </div>
      </div>
      <div class="product-detail-info">
        <h3 class="product-detail-info-title">Product Details</h3>
        <div
          class="product-detail-info-description"
          v-html="product.detailInfo"
        ></div>
      </div>
      <div class="product-detail-info">
        <h3 class="product-detail-info-title">Nutrition Information</h3>
        <div
          class="product-detail-info-description"
          v-html="product.serveInfo"
          v-if="product.serveInfo !== ''"
        ></div>
        <div class="product-detail-info-description">
          <el-table :data="product.healthInfo" border stripe="stripe">
            <el-table-column prop="items" label="Items"> </el-table-column>

            <el-table-column
              prop="qtyPS"
              v-if="product.healthInfo[0].qtyPS !== undefined"
              :label="'Avg.Qty \n per Serve'"
            >
            </el-table-column>

            <el-table-column
              prop="qtyP100"
              v-if="product.healthInfo[0].qtyP100 !== undefined"
              :label="'Avg.Qty \n per 100g'"
            >
            </el-table-column>

            <el-table-column
              v-if="product.healthInfo[0].NRV !== undefined"
              prop="NRV"
              label="%NRV"
            >
            </el-table-column>
          </el-table>
        </div>


        <div
          class="product-detail-info-description"
          v-html="product.nrvInfo"
          v-if="product.nrvInfo !== ''"
        ></div>
      </div>
    </div>

    <!-- PC端 Element-UI 响应式布局 -->
    <el-row :gutter="20" class="hidden-md-and-down">
      <el-col :span="12">
        <div class="product-detail-img-and-add">
          <div class="product-detail-img">
            <el-image
              style="width: 16.668rem"
              :src="product.imgUrl"
              fit="contain"
            ></el-image>
          </div>
          <div class="product-detail-add-cart">
            <h2>{{ product.productName }}</h2>

          <!-- // 20/11/2023 根据需求，产品加入购物车按钮， 根据需求隐藏该按钮(并隐藏产品价格)， 替换为微信二维码，  -->
          <el-button
            type="success"
            style="
                margin-bottom: 0.625rem;
                font-size: 1rem;
                font-weight: bold;
              "
            @click.native="goContactUs"
          >
            详情咨询
          </el-button>


          <!-- 
            <p>{{ product.priceAUD }}</p>
            <el-button
              v-if="productNum === 0"
              round
              style="
                color: #99b2d1;
                border: 1px #99b2d1 solid;
                margin-bottom: 0.625rem;
              "
              @click.native="addToCart"
              >ADD TO CART</el-button
            >

            <el-input-number
              class="product-input-number"
              v-if="productNum !== 0"
              v-model="productNum"
              @change="handleChange"
              :min="0"
              :max="10"
              label="描述文字"
            ></el-input-number>
          -->
          
          </div>
        </div>
      </el-col>

      <el-col :span="8" style="margin-top: 42px">
        <div class="product-detail-info">
          <h3 class="product-detail-info-title">Product Details</h3>
          <div
            class="product-detail-info-description"
            v-html="product.detailInfo"
          ></div>
        </div>
        <div class="product-detail-info">
          <h3 class="product-detail-info-title">Nutrition Information</h3>
          <div
            class="product-detail-info-description"
            v-html="product.serveInfo"
            v-if="product.serveInfo !== ''"
          ></div>
          <div class="product-detail-info-description">
            <el-table :data="product.healthInfo" border stripe="stripe">
              <el-table-column prop="items" label="Items"> </el-table-column>

              <el-table-column
                prop="qtyPS"
                v-if="product.healthInfo[0].qtyPS !== undefined"
                :label="'Avg.Qty \n per Serve'"
              >
              </el-table-column>

              <el-table-column
                prop="qtyP100"
                v-if="product.healthInfo[0].qtyP100 !== undefined"
                :label="'Avg.Qty \n per 100g'"
              >
              </el-table-column>

              <el-table-column
                v-if="product.healthInfo[0].NRV !== undefined"
                prop="NRV"
                label="%NRV"
              >
              </el-table-column>
            </el-table>
          </div>

          <div
            class="product-detail-info-description"
            v-html="product.nrvInfo"
            v-if="product.nrvInfo !== ''"
          ></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import products from "@/assets/products";
import { getCart, updateCart } from "@/utils/store";
import "element-ui/lib/theme-chalk/display.css";

export default {
  name: "ProductDetail",
  data() {
    return {
      product: {},
      productNum: 0,
    };
  },
  methods: {
    handleChange() {
      updateCart(this.product.productId, this.productNum);
      this.$emit("updateCart");
    },
    addToCart() {
      this.productNum++;
      updateCart(this.product.productId, this.productNum);
      this.$emit("updateCart");
    },
    // 20/11/2023 根据需求， 增加详情咨询，跳转到咨询页面
    goContactUs() {
      this.$router.push("/contactUs");
    },
    initProductNum() {
      let cartList = getCart().split("-");
      this.productNum = cartList[this.product.productId];
    },
  },
  created() {
    this.product = products[this.$route.params.id];
    this.initProductNum();
  },
};
</script>

<style scoped>
.product-detail-img-and-add {
  background-color: white;
  width: 100%;
  /* align center */
  margin: 0 auto;
}
.product-detail-img {
  margin-top: 5%;
  /* background-color: #fdfdfd; */
}
.product-detail-add-cart {
  height: 100%;
}
.product-detail-info {
  float: left;
  background: #fdfdfd;
  width: 100%;
}
.product-detail-info-title {
  width: 100%;
  float: left;
  text-align: left;
  padding-left: 20px;
}
.product-detail-info-description {
  width: 90%;
  float: left;
  text-align: justify;
  padding-left: 20px;
}
/deep/ .el-table .cell {
  white-space: pre-line;
  text-align: left;
}
/deep/ .el-input__inner {
  border-radius: 0 !important;
}
</style>
