<!--
  Created by Lingjun Meng on 28/6/2022
-->
<template>
  <div>
    <div>
      <el-image
        style="width: 110%; margin-left: -5%"
        src="/static/img/product_gallery.jpeg"
        fit="contain"
      ></el-image>
    </div>
    <div>
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="margin-top: 2%; padding-left: 1.4286rem"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item>Product List</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!-- Mobile 端 -->
    <div class="hidden-md-and-up">
      <div style="width: 92%; margin: auto">
        <product
          v-for="(item, index) in products"
          @updateCart="updateCart"
          ref="product"
          :product-item="item"
          :key="index"
        ></product>
      </div>
    </div>

    <!-- PC 端 -->
    <div class="hidden-md-and-down">
      <el-row>
        <!-- left column -->
        <el-col :span="12"
          ><div>
            <product
              id="webproduct1"
              v-for="(item, index) in products" v-if="(index % 2 == 0)"
              @updateCart="updateCart"
              ref="product"
              :product-item="item"
              :key="index"
            >
            </product></div
        ></el-col>

        <!-- right column -->
        <el-col :span="12">
          <div>
            <product
              id="webproduct2"
              v-for="(item, index) in products" v-if="(index % 2 != 0)"
              @updateCart="updateCart"
              ref="product"
              :product-item="item"
              :key="index"
            >
            </product></div
        ></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Product from "@/views/product/compunents/product";
import { getCart } from "@/utils/store";

export default {
  name: "index",
  components: { Product },
  data() {
    return {
      products: [
        {
          productId: 0,
          imgUrl: "/static/img/uht_200_01.jpeg",
          productName: "UHT 200",
          imageSize: "80%",
          // priceAUD: "$ 22.95",
        },
        {
          productId: 1,
          imgUrl: "/static/img/uht_1000_01.jpeg",
          productName: "UHT 1000",
          imageSize: "80%",
          // priceAUD: "$ 24.95",
        },
        {
          productId: 2,
          imgUrl: "/static/img/full_cream_milk_powder_01.png",
          productName: "Full Cream Milk powder",
          imageSize: "80%",
          // priceAUD: "$ 22.95",
        },
        {
          productId: 3,
          imgUrl: "/static/img/lactoferrin_milk_powder_01.jpeg",
          productName: "Lactoferrin Milk Powder",
          imageSize: "80%",
          // priceAUD: "$ 28.80",
        },
        {
          productId: 4,
          imgUrl: "/static/img/growth_care_01.png",
          productName: "Growth Care",
          imageSize: "80%",
          // priceAUD: "$ 19.95",
        },
      ],
    };
  },
  methods: {
    goBack() {
      console.log("go back");
    },
    updateCart() {
      this.$emit("updateCart");
    },
    initProductNum() {
      let cartList = getCart().split("-");
      this.productNum = cartList[this.productItem.productId];
    },
    updateProductListItem(param) {
      console.log("Hello ");
      this.$refs.product[param.productId].updateProductNum(param);
    },
  },
};
</script>

<style scoped>
#webproduct1 {
  width: 88%;
  margin-left: 3%;
}

#webproduct2 {
  width: 88%;
}
</style>
