<!--
  Created by Lingjun Meng on 21/7/2022
-->
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-top: 2%" :label-position="'top'">
      <el-breadcrumb-item @click.native="changeTab('cart')">Cart</el-breadcrumb-item>
      <el-breadcrumb-item @click.native="changeTab('checkout')">Check Out</el-breadcrumb-item>
      <el-breadcrumb-item @click.native="changeTab('shipping')">Shipping</el-breadcrumb-item>
      <el-breadcrumb-item>Payment</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    <order :order-items="productList">-->
    <!--    </order>-->
    <div class="box-card">
      <div class="box-contact-info">
        <div>Contact</div>
        <div style="color: black;">{{ contactInfo.email }}</div>
        <!--        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
      </div>
      <div class="box-shipping-info">
        <div style="">
          Ship to
        </div>
        <div style="color: black;">
          {{ contactInfo.address + ', ' + contactInfo.city + ', ' + contactInfo.state }}

        </div>
      </div>
      <div class="box-shipping-info">
        <div style="">
          Shipping Method
        </div>
        <div style="color: black;">
          Australia Post

        </div>
      </div>
    </div>
    <div>
      <h3>Payment</h3>
      <p>
        All transactions are secure and encrypted.
      </p>
      <form @submit.prevent="handleSubmit">
        <div id="payment-element"/>
        <el-button native-type="submit" type="primary" round >Complete</el-button>
      </form>

      <!--      <el-card class="box-card">-->
      <!--        <div slot="header" class="clearfix">-->
      <!--          <el-radio v-model="radio" label="1">Credit Card</el-radio>-->
      <!--&lt;!&ndash;          <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>&ndash;&gt;-->
      <!--        </div>-->
      <!--        <div id="payment-element"/>-->
      <!--&lt;!&ndash;        <el-form :model="cardInfo">&ndash;&gt;-->
      <!--&lt;!&ndash;          <el-form-item>&ndash;&gt;-->
      <!--&lt;!&ndash;            <el-input v-model="cardInfo.number" placeholder="Card Number"></el-input>&ndash;&gt;-->
      <!--&lt;!&ndash;          </el-form-item>&ndash;&gt;-->
      <!--&lt;!&ndash;          <el-form-item>&ndash;&gt;-->
      <!--&lt;!&ndash;            <el-input v-model="cardInfo.name" placeholder="Cardholder Name"></el-input>&ndash;&gt;-->
      <!--&lt;!&ndash;          </el-form-item>&ndash;&gt;-->
      <!--&lt;!&ndash;          <el-form-item>&ndash;&gt;-->
      <!--&lt;!&ndash;            <el-input v-model="cardInfo.expireDate" placeholder="Expiration date (MM/YY)"></el-input>&ndash;&gt;-->
      <!--&lt;!&ndash;          </el-form-item>&ndash;&gt;-->
      <!--&lt;!&ndash;          <el-form-item>&ndash;&gt;-->
      <!--&lt;!&ndash;            <el-input v-model="cardInfo.cvc" placeholder="Security Code"></el-input>&ndash;&gt;-->
      <!--&lt;!&ndash;          </el-form-item>&ndash;&gt;-->
      <!--&lt;!&ndash;        </el-form>&ndash;&gt;-->
      <!--      </el-card>-->
    </div>
    <!--    <div>-->
    <!--      <el-button style="margin-left: 40%;margin-top: 5%" @click="pay()">-->
    <!--       pay-->
    <!--      </el-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import Order from "@/views/cart/components/Order";
import {loadStripe} from "@stripe/stripe-js";

export default {
  name: "payment",
  components: {Order},
  props: {
    contactInfo: {
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      postCode: '',
      phone: ''
    },
    orderProducts: []
  },
  data() {
    return {
      stripe: undefined,
      elements: undefined,
      valueFields: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
      },
      productList: [],
      cardInfo: {
        number: '',
        name: '',
        expireDate: '',
        cvc: ''
      },

      radio: '1',
      inputFields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        cardCvv: 'v-card-cvv'
      },
      loading: false,
      lineItems: [
        {
          price: 'some-price-id', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: 'your-success-url',
      cancelURL: 'your-cancel-url',
      publishableKey: 'pk_test_51LPad2Ehc7AQYGaqlO1mK2lR5ie5M0BpkeK7ptbdM2HiAmyO52hEbdugPTewDu0BdtEy2Q9iVIJbstV7BcFgmnlG000Lg3VMIa'
    };
  },
  methods: {
    pay() {

    },
    async initStripe() {
      this.stripe = await loadStripe('xxxxxxxxx');
      this.elements = this.stripe.elements({
        theme: 'stripe',
        clientSecret: 'xxxxxx',
        locale: 'auto'
      })
      const paymentElement = this.elements.create("payment");
      paymentElement.mount("#payment-element");
    },
    async handleSubmit(e) {
      e.preventDefault();
      let elements = toRaw(this.elements)
      let stripe = toRaw(this.stripe)
      const {error} = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "http://localhost:4242/checkout.html",
        },
        //redirect: 'if_required'如果设置redirect: 'if_required'则不跳转returnUrl
      })
      console.log(error)
    },
    changeTab(tab) {
      this.$emit('changeTab', tab)
    }
    ,
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    }
    ,
  }
  ,
  created() {
    this.initStripe()
    this.productList = this.orderProducts
  }
}
</script>

<style scoped>
.box-card {
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
  margin-top: 5%;
}

.box-card .box-contact-info {
  width: 90%;
  float: left;
  margin-left: 5%;
  font-size: 16px;
  min-height: 50px;
  border-bottom: #d9d9d9 1px solid;
}

.box-card .box-shipping-info {
  width: 90%;
  margin-left: 5%;
  font-size: 16px;
  min-height: 50px;
  border-bottom: #d9d9d9 1px solid;
}

.box-card .shipping-method {
  width: 90%;
  margin-left: 5%;
  font-size: 16px;
  min-height: 50px;
}

/deep/ .el-input__inner {
  border-radius: 0 !important;
}
</style>
