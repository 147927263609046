<!--
  Created by Lingjun Meng on 28/2/2023
-->
<template>
  <div class="batch-main-content">
    <br />
    <br />
    <br />
    <br />
    <br />

    <div style="font-size: 2.125rem">
      <strong>LACTOFERRIN P201202</strong>
    </div>

    <br />
    <br />

    <br />
    <div style="font-size: 1.4rem">
      <strong>KINNS DAIRY LACTOFERRIN</strong>
    </div>

    <br />
    <br />

    <!-- LEFT Text Content -->
    <div style="font-size: 1.125rem; text-align: justify">
      <p>
        Kings Milk Pty Ltd is the sole brand owner and supplier of the renowned
        “KINNS DAIRY” products in Australia. The Products are all 100% make in
        Australia. Being certified by many international food and health
        organizations. Our products meet the highest International and Australia
        health and quality standards. The manufacturing process of products is
        TGA approved by the Australian Government Department of Health.
      </p>

      <br />

      <p>
        皇室奶品集团创立自主品牌“KINNS
        DAIRY”。其旗下所有产品均为100%澳大利亚制造，符合澳大利亚及国际高端奶制品标准。生产工厂拥有澳大利亚治疗产品管理局（TGA）生产制造许可。
      </p>
    </div>

    <el-row :gutter="16">
      <!-- 左边内容 -->
      <el-col :span="11">
        <br />

        <!-- Product Image -->
        <div class="demo-image__lazy">
          <el-image
            v-for="url in urls"
            :key="url"
            :src="url"
            lazy
            style="width: 60%"
          ></el-image>
        </div>
      </el-col>
      <!--  中间空 -->
      <el-col :span="2"><div>&nbsp;</div></el-col>
      <!-- 右边内容 -->
      <el-col :span="11"
        ><div>
          <div style="font-size: 1.125rem; text-align: justify">
            <br />
            <p>
              The Kinns Dairy lactoferrin formula is designed to deliver higher
              strength lactoferrin and an easy to absord form of FOS and LF to
              support the body’s immune system.
            </p>
            <p>
              The immune system is the body’s natural defence mechanism, it
              helps defend the body from infection. Lactoferrin is an
              iron-binding protein found in milk and is also a natural component
              of every person’s immune system. It supports the function of the
              immune system.
            </p>
            <p>
              Maintaining a healthy intake of lactoferrin along with the
              essential mineral zinc helps support the health of your immune
              system and daily wellbeing.
            </p>
            <ul style="margin: 0; padding: 30 30 30 30; text-align: left">
              <li>Used to support daily immune systems health & fundtion</li>
              <li>Add FOS to improve intestinal absorption function</li>
              <li>Add Lactase to provent lactose intolerance</li>
              <li>No added gluten, yeast, nuts or egg</li>
              <li>Made in Australia</li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>

    <br />
    <br />

    <!-- 产品批次表格 -->
    <el-row :gutter="16">
      <!-- 左边空出 -->
      <el-col :span="1">&nbsp;</el-col>
      <!-- 中间内容 -->
      <el-col :span="22">
        <div style="font-size: 1.4rem">
          <strong>Batch Traceability Info</strong>
          <br />
          批次溯源信息
        </div>
        <br />

        <!-- EL-TABLE -->
        <el-table
          :data="tableData"
          style="width: 100%"
          row-key="id"
          border
          lazy
          :load="load"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          :show-header="showHeader"
        >
          <el-table-column
            class-name="el-table-lable-box"
            prop="date"
            align="left"
            label=""
            width="325"
          ></el-table-column>

          <el-table-column
            class-name="el-table-content-box"
            prop="name"
            align="left"
            label=""
            width="1050"
          ></el-table-column>
        </el-table>
      </el-col>
      <!-- 右边空出 -->
      <el-col :span="1">&nbsp;</el-col>
    </el-row>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    
  </div>
</template>

<script>
import productImageUrl from "@/assets/img/LACTOFERRIN.png";
export default {
  data() {
    return {
      urls: [productImageUrl], // Product Image

      showHeader: false,
      tableData: [
        {
          id: 1,
          date: "Issueed By/检验机构",
          name: "Dairy Technical Services t/a DTS Food Assurance",
        },
        {
          id: 2,
          date: "Test Result/检测结果",
          name: "The product has been successfully tested 该产品已成功通过检测",
        },
        {
          id: 3,
          date: "Batch Traceability Code/批次溯源码",
          name: "CBS1814003",
        },
        {
          id: 4,
          date: "Place of Origin/原产地",
          name: "Australia/澳大利亚",
        },
        {
          id: 5,
          date: "Brand Owner/品牌商",
          name: "Kings Milk Pty Ltd",
        },
        {
          id: 6,
          date: "Product Date/生产日期",
          name: "08 DEC 2020",
        },
        {
          id: 7,
          date: "Loading Port/装运港口",
          name: "Melbourne, Australia",
        },
        {
          id: 8,
          date: "Batch Number/产品批次",
          name: "ACX014",
        },
        {
          id: 9,
          date: "Origin Certificate",
          name: "原产地证书",
          children: [
            {
              id: 91,
              date: "Issued By/发证机构",
              name: "Australian Chamber of Commerce and Industry",
            },
            {
              id: 92,
              date: "Certificate No./证书编号",
              name: "EC202176557",
            },
          ],
        },
        {
          id: 10,
          date: "Health Certificate",
          name: "健康卫生证书",
          children: [
            {
              id: 103,
              date: "Issued By/发证机构",
              name: "Department of Agriculture, Fisheries and Forestry",
            },
            {
              id: 104,
              date: "Certificate No./证书编号",
              name: "HC9753947",
            },
          ],
        },
      ],
    };
  },
  methods: {
    load(tree, treeNode, resolve) {
      setTimeout(() => {
        resolve([
          {
            id: 91,
            date: "Issued By/发证机构",
            name: "Australian Chamber of Commerce and Industry",
          },
          {
            id: 92,
            date: "Certificate No./证书编号",
            name: "EC202176557",
          },
          {
            id: 103,
            date: "Issued By/发证机构",
            name: "Department of Agriculture, Fisheries and Forestry",
          },
          {
            id: 104,
            date: "Certificate No./证书编号",
            name: "HC9753947",
          },
        ]);
      }, 1000);
    },
  },
  name: "BatchesDetail",
  mounted() {
    console.log(this.$route.params);
  },
};
</script>

<style scoped>
.batch-main-content {
  width: 70%;
  margin: auto;
}
/deep/.el-table-lable-box {
  font-weight: bold;
  font-size: 1.125rem;
  color: #7e7ec0;
}

/deep/.el-table-content-box {
  font-size: 1.125rem;
}
</style>
