import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh_cn from "./zh-cn";
import en_us from "./en-us";
Vue.use(VueI18n)

// 注册i18n实例并引入语言文件
const i18n = new VueI18n({
  locale: window.localStorage.getItem('language') == null ? "en" : window.localStorage.getItem('language'),
  messages: {
    'zh': zh_cn,
    'en': en_us
  }
})

export default i18n
