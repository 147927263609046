<!--
  Created by Lingjun Meng on 22/7/2022
-->
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-top: 2%" :label-position="'top'">
      <el-breadcrumb-item @click.native="changeTab('cart')">Cart</el-breadcrumb-item>
      <el-breadcrumb-item @click.native="changeTab('checkout')">Check Out</el-breadcrumb-item>
      <el-breadcrumb-item>Shipping</el-breadcrumb-item>
    </el-breadcrumb>
    <order :order-items="productList">
    </order>
    <div class="box-card">
      <div class="box-contact-info">
        <div>Contact</div>
        <div style="color: black;">{{contactInfo.email}}</div>
<!--        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
      </div>
      <div  class="box-shipping-info">
        <div style="">
          Ship to
        </div>
        <div style="color: black;">
          {{contactInfo.address + ', ' + contactInfo.city + ', ' + contactInfo.state}}

        </div>
      </div>
    </div>
    <h3>
      Shipping Method
    </h3>

      <el-radio style="margin-left: 3%; margin-top: 5%" v-model="radio" label="1">Australia Post</el-radio>

    <div>
      <el-button style="margin-left: 25%" @click="handlePayment()">
        Continue to payment
      </el-button>
    </div>
  </div>
</template>

<script>
import Order from "@/views/cart/components/Order";

export default {
  name: "shiping",
  components: {Order},
  props:{
    contactInfo: {
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      postCode: '',
      phone: ''
    },
    orderProducts: []
  },
  data(){
    return {
      radio: '1',
      shippingInfo: {},
      productList: []
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    changeTab(tab){
      this.$emit('changeTab', tab)
    },
    handlePayment(){
      this.$emit('setShippingInfo', this.shippingInfo)
      this.$emit('changeTab', 'payment')
    }
  },
  created() {
    this.productList = this.orderProducts
  }
}
</script>

<style scoped>
.box-card{
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
  margin-top: 5%;
}
.box-card .box-contact-info {
  width: 90%;
  float: left;
  margin-left: 5%;
  font-size: 16px;
  min-height: 50px;
  border-bottom: #d9d9d9 1px solid;
}
.box-card .box-shipping-info{
  width: 90%;
  margin-left: 5%;
  font-size: 16px;
  min-height: 50px;
}
.shipping-method{
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
  margin-top: 5%;
  min-height: 50px;
}
</style>
