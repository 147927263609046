import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import locale from 'element-ui/lib/locale/lang/en'
import i18n from "@/assets/lang";
import router from './router'
import 'element-ui/lib/theme-chalk/display.css';

import VueCardPayment from 'vue-card-payment'
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'vue-card-payment/dist/vue-card-payment.css'

Vue.use(VueCardPayment)

Vue.config.productionTip = false
Vue.use(ElementUI, { locale });


new Vue({
  render: h => h(App),
  i18n,
  router
}).$mount('#app')
