<template>
  <el-container class="main-container">
    <!-- Mobile端 -->
    <div class="hidden-sm-and-up">
      <el-header class="main-header">
        <el-row style="margin-left: -10px; height: 30px; padding-top: 20px">
          <el-col :span="18" style="float: left">
            <router-link to="/home">
              <el-avatar
                fit="fill"
                size="large"
                :src="require('./assets/img/kinns-logo.png')"
                style="margin-top: -10px; float: left"
              ></el-avatar>
            </router-link>
          </el-col>
          <el-col :span="2">
            <el-badge :value="cartNum" class="item" @click.native="viewCart()">
              <i class="el-icon-shopping-cart-2 header-icon"></i>
            </el-badge>
          </el-col>
          <el-col :span="2">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <i class="el-icon-user-solid header-icon"></i>
              </span>
              <el-dropdown-menu
                v-if="userStatus === 'notLogin'"
                slot="dropdown"
              >
                <el-dropdown-item @click.native="loginFormVisible = true">
                  Login
                </el-dropdown-item>
              </el-dropdown-menu>

              <el-dropdown-menu v-if="userStatus !== 'login'" slot="dropdown">
                <el-dropdown-item @click.native="loginFormVisible = true">
                  My Account
                </el-dropdown-item>
                <el-dropdown-item @click.native="loginFormVisible = true">
                  My Order
                </el-dropdown-item>
                <el-dropdown-item> Log Out </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>

          <el-col :span="2">
            <el-dropdown trigger="click" :hide-on-click="false">
              <span class="el-dropdown-link">
                <i class="el-icon-s-fold header-icon"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="jumpTo('/home')">
                  Home
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-dropdown trigger="click" :hide-on-click="true">
                    <span class="el-dropdown-link"> Product </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="jumpTo('/product')">
                        Product List
                      </el-dropdown-item>
                      <el-dropdown-item
                        @click.native="jumpTo('/productBatches')"
                      >
                        Product Batches
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-dropdown-item>

                <el-dropdown-item @click.native="jumpTo('/contactUs')"
                  >Contact us</el-dropdown-item
                >
                <el-dropdown-item @click.native="jumpTo('/faq')"
                  >FAQ</el-dropdown-item
                >
                <el-dropdown-item @click.native="changeLanguage"
                  >en/中</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
        <!--      Login Dialog-->
        <el-dialog
          :title="isLogin === true ? 'Login' : 'Register'"
          width="80%"
          center
          :visible.sync="loginFormVisible"
          :append-to-body="true"
        >
          <el-form :model="loginForm" v-if="isLogin">
            <el-form-item label="Email" :label-width="formLabelWidth">
              <el-input v-model="loginForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Password" :label-width="formLabelWidth">
              <el-input
                v-model="loginForm.password"
                show-password
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-form :model="registerForm" v-if="!isLogin">
            <el-form-item label="Email" :label-width="formLabelWidth">
              <el-input
                v-model="registerForm.email"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="Password" :label-width="formLabelWidth">
              <el-input
                v-model="registerForm.password"
                show-password
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="Password" :label-width="formLabelWidth">
              <el-input
                v-model="registerForm.rePassword"
                show-password
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" style="margin: auto" v-if="isLogin">
            <el-button @click="isLogin = false">Register</el-button>
            <el-button type="primary" @click="handleLogin">Login</el-button>
          </div>
          <div slot="footer" style="margin: auto" v-if="!isLogin">
            <el-button @click="isLogin = true">Back</el-button>
            <el-button type="primary" @click="handleRegister"
              >Register</el-button
            >
          </div>
        </el-dialog>
      </el-header>
    </div>

    <!-- PC端 -->
    <div class="hidden-xs-only">
      <el-header class="main-header">
        <el-row style="margin-left: -10%; height: 30px; padding-top: 20px">
          <!-- Menu 1 -->
          <el-col :span="12">
            <el-menu
              :default-active="menuIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleMenuSelect"
              style="
                height: 59px;
                margin-top: -20px;
                float: right;
                background-color: #fbfbfb;
                font-width: bold;
              "
            >
              <!-- Home Menu -->
              <el-menu-item index="1"><a href="/#/">Home</a></el-menu-item>

              <!-- Product Menu -->
              <el-submenu index="2">
                <template slot="title">Product</template>
                <el-menu-item index="2-1"
                  ><a href="/#/product">Product List</a></el-menu-item
                >
                <el-menu-item index="2-2"
                  ><a href="/#/productBatches">Product Batches</a></el-menu-item
                >
              </el-submenu>

              <!-- Contact Us Menu -->
              <el-menu-item index="3"
                ><a href="/#/contactUs">Contact Us</a></el-menu-item
              >

              <!-- FAQ Menu -->
              <el-menu-item index="4"><a href="/#/faq">FAQ</a></el-menu-item>
            </el-menu>
          </el-col>

          <!-- LOGO -->
          <el-col style="float: left; padding-left: 4%" :span="2">
            <router-link to="/home">
              <el-avatar
                fit="fill"
                size="large"
                :src="require('./assets/img/kinns-logo.png')"
                style="margin-top: -10px; float: left"
              >
              </el-avatar>
            </router-link>
          </el-col>

          <!-- Account MENU -->
          <el-col style="float: left; padding-left: 6%" :span="2">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <i class="el-icon-user-solid header-icon"></i>
              </span>
              <el-dropdown-menu
                v-if="userStatus === 'notLogin'"
                slot="dropdown"
              >
                <el-dropdown-item @click.native="loginFormVisible = true">
                  Login
                </el-dropdown-item>
              </el-dropdown-menu>

              <el-dropdown-menu v-if="userStatus !== 'login'" slot="dropdown">
                <el-dropdown-item @click.native="loginFormVisible = true">
                  My Account
                </el-dropdown-item>
                <el-dropdown-item @click.native="loginFormVisible = true">
                  My Order
                </el-dropdown-item>
                <el-dropdown-item> Log Out </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>

          <!-- CART Menu -->
          <el-col style="float: left; padding-left: 6%" :span="2">
            <el-badge :value="cartNum" class="item" @click.native="viewCart()">
              <i class="el-icon-shopping-cart-2 header-icon"></i>
            </el-badge>
          </el-col>
        </el-row>

        <!--      Login Dialog-->
        <el-dialog
          :title="isLogin === true ? 'Login' : 'Register'"
          width="80%"
          center
          :visible.sync="loginFormVisible"
          :append-to-body="true"
        >
          <el-form :model="loginForm" v-if="isLogin">
            <el-form-item label="Email" :label-width="formLabelWidth">
              <el-input v-model="loginForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Password" :label-width="formLabelWidth">
              <el-input
                v-model="loginForm.password"
                show-password
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-form :model="registerForm" v-if="!isLogin">
            <el-form-item label="Email" :label-width="formLabelWidth">
              <el-input
                v-model="registerForm.email"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="Password" :label-width="formLabelWidth">
              <el-input
                v-model="registerForm.password"
                show-password
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="Password" :label-width="formLabelWidth">
              <el-input
                v-model="registerForm.rePassword"
                show-password
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" style="margin: auto" v-if="isLogin">
            <el-button @click="isLogin = false">Register</el-button>
            <el-button type="primary" @click="handleLogin">Login</el-button>
          </div>
          <div slot="footer" style="margin: auto" v-if="!isLogin">
            <el-button @click="isLogin = true">Back</el-button>
            <el-button type="primary" @click="handleRegister"
              >Register</el-button
            >
          </div>
        </el-dialog>
      </el-header>
    </div>
    <el-main style="margin-top: 50px">
      <router-view
        @updateCart="renderCartNum"
        :orderProducts="orderProducts"
        ref="rout"
      ></router-view>
      <cart
        :cartVisible="cartVisible"
        @viewCart="viewCart"
        @updateCart="renderCartNum"
        @checkOutCart="checkOutCart"
        @updateProductListItem="updateProductListItem"
        ref="cart"
      ></cart>
    </el-main>
    <el-footer class="main-footer">
      <!-- Mobile端 -->
      <!-- !ToDo Add Home & Contact Us Redirections -->
      <div class="hidden-sm-and-up">
        <el-image
          style="width: 110%; margin-left: -5%; margin-top: -2%"
          src="/static/img/footer_contact_us.jpeg"
          fit="contain"
        >
        </el-image>
      </div>

      <!-- PC端 -->
      <div class="hidden-xs-only">
          <div style="position: relative; width: 100%;margin-left: -5%;margin-top: -2%; height: 10px;">
            <!-- <img src="/static/img/footer_contact_us.jpeg" width="105%"> -->

            <img src="/static/img/footer_contact_us.jpeg" class="cover" width="242" height="363" />
            <span id="footext" style="position: absolute; top: 6.9rem; left:46.5%; color:#606266">
              <a href="#">Home</a>&emsp;|&emsp;<a href="/#/contactUs">Contact Us</a><br>
            </span>

        </div>

      </div>
    </el-footer>
  </el-container>
</template>

<script>
import { getCart, initCart, setUserInfo } from "@/utils/store";
import { login, register } from "@/api/sso";
import cart from "@/views/cart";
import { addProducts } from "@/api/product";
import products from "@/assets/products";

export default {
  name: "App",
  components: {
    cart,
  },
  data() {
    return {
      menuIndex: "1",
      cartNum: 0,
      cartVisible: false,
      orderProducts: [],
      loginFormVisible: false,
      dialogTableVisible: false,
      dialogFormVisible: false,
      userStatus: "notLogin",
      isLogin: true,
      loginForm: {
        email: "",
        password: "",
      },
      registerForm: {
        email: "",
        password: "",
        rePassword: "",
      },
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "80px",
      menuOptions: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
                {
                  value: "fankui",
                  label: "反馈",
                },
                {
                  value: "xiaolv",
                  label: "效率",
                },
                {
                  value: "kekong",
                  label: "可控",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    renderCartNum() {
      let cartList = getCart().split("-");
      this.cartNum = 0;
      for (let i = 0; i < cartList.length; i++) {
        this.cartNum += parseInt(cartList[i]);
      }
    },
    jumpTo(p) {
      this.$router.push(p).catch(() => {
        /* ignore */
      });
    },
    viewCart() {
      this.cartVisible = !this.cartVisible;
      this.$refs.cart.renderCart();
    },
    updateProductListItem(param) {
      console.log(param);
      this.$refs.rout.updateProductListItem(param);
    },
    checkOutCart(param) {
      console.log("params", param);
      this.orderProducts = param;
      this.$router.push("/checkout");
    },
    changeLanguage() {
      this.$i18n.locale = this.$i18n.locale === "zh" ? "en" : "zh";
      //
      // addProducts(products).then(() =>{
      //
      // })
    },
    jumpToRegister() {
      this.isLogin = false;
    },
    handleRegister() {
      let param = {
        email: this.registerForm.email,
        password: this.registerForm.password,
      };
      register(param).then((res) => {
        setUserInfo(res);
        this.$message({
          message: "Register Success!",
          type: "success",
        });
      });
      this.loginFormVisible = false;
    },

    handleLogin() {
      let param = {
        email: this.loginForm.email,
        password: this.loginForm.password,
      };
      login(param).then((res) => {
        setUserInfo(res);
        this.$message({
          message: "Login Success!",
          type: "success",
        });
      });
      this.loginFormVisible = false;
    },
    handleMenuSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    showMenu() {},
  },
  created() {
    initCart();

    this.renderCartNum();
  },
  watch: {},
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

.main-container {
  /*font-family: SofiaProLight, Avenir, arial, sans-serif;*/
  font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #807dc5;
  /*background-color: #f2ece0;*/
  background-color: #f7f7f7;
  margin-top: -9px;
  margin-left: -2%;
  width: 104%;
}

.main-container .main-header {
  position: fixed;
  z-index: 999;
  width: 100%;
  /* background-color: #99ccff; */
  background-color: #fbfbfb;
  box-shadow: 0 2px 4px #0000001a;
  /*padding-left: 60px;*/
  top: 0px;
  left: 0px;
}

.main-container .main-header .header-icon {
  font-size: 20px;
}

.main-container .main-footer {
  /* background-color: #99ccff; */
  background-color: #fbfbfb;
  height: 81px !important;
}

.main-container .main-footer .footer-copyright {
  font-size: 10px;
}
.my-canvas {
  position: fixed;
  top: -99999999999px;
  left: -99999999999px;
  z-index: -99999999999;
  opacity: 0;
}
.img {
  width: 100%;
  height: auto;
}

@import url("https://use.typekit.net/oov2wcw.css");
@import url("https://fonts.googleapis.com/css2?family=Caveat:display=swap");
#footext {
  font-size: 11px;
}
#footext a:visited {
  color: #606266;
  text-decoration: none;
  font-weight: 550;
} /* visited link */
#footext a:link {
  color: #606266;
  text-decoration: none;
  font-weight: 550;

}

/* Not Yet visited tab */
.el-menu-item a:visited {
  text-decoration: none;
  color: #606266;

} /* visited tab */
.el-menu-item a:link {
  text-decoration: none;
  color: #606266;
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #606266;
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-style: normal;
}

p,
a {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: century-gothic, sans-serif;
  font-weight: 600;
  font-style: normal;
}


.cover {
  object-fit: cover;
  width: 105%;
  height: 250px;
}
</style>
