<!--
  Created by Lingjun Meng on 30/6/2022
-->
<template>

    <el-row class="cart-item">
      <el-col :span="8">
        <img :src=cartItem.imgUrl class="cart-item-img">

      </el-col>
      <el-col :span="10">
        <h3>{{cartItem.productName}}</h3>
        <p>{{cartItem.priceAUD}}</p>
      </el-col>
      <el-col :span="6">
        <p class="cart-item-ttl-price">$</p>
        <el-input-number
          class="cart-item-num"
          size="mini"
          :min="0" v-model="num4"
          @change="handleChange"
          controls-position="right"></el-input-number>
      </el-col>
    </el-row>

</template>

<script>
export default {
  name: "CartItem",
  props: {
    productId: 0,
    productNum: 0
  },
  data() {
    return {
      num4: 0,
      cartItem: {
      },
      products: [{
        productId: 0,
        imgUrl: "/static/img/uht_200_01.jpeg",
        productName: 'UHT 200',
        imageSize: "80%",
        priceAUD: '$22.95'
      },
        {
          productId: 1,
          imgUrl: "/static/img/uht_1000_01.jpeg",
          productName: 'UHT 1000',
          imageSize: "80%",
          priceAUD: '24.95'
        }, {
          productId: 2,
          imgUrl: "/static/img/full_cream_milk_powder_01.png",
          productName: 'Full Cream Milk powder',
          imageSize: "80%",
          priceAUD: '$22.95'
        },{
          productId: 3,
          imgUrl: "/static/img/lactoferrin_milk_powder_01.jpeg",
          productName: 'Lactoferrin Milk Powder',
          imageSize: "80%",
          priceAUD: '$28.80'
        },{
          productId: 4,
          imgUrl: "/static/img/growth_care_01.png",
          productName: 'Growth Care',
          imageSize: "80%",
          priceAUD: '$19.95'
        }]
    }
  },
  methods:{
    handleChange(value){
      this.$emit('changeItemNum', {productId: this.productId, productNum: value})
    }
  },
  created() {
    this.cartItem = this.products[this.productId]
    this.num4 = this.productNum
  }
}
</script>

<style scoped>

.cart-item{
  width: 100%;
  margin: auto;
  /*box-shadow: 0 5px 10px #0000001a;*/
}

.cart-item .cart-item-img{
  max-height: 100px;
  margin-left: 10%;
  margin-top: 10%;

}
.cart-item-ttl-price{
  margin-top: 25%;
}
.cart-item-num{
  width: 80%;
}
</style>
