<!--
  Created by Lingjun Meng on 12/8/2022
-->
<template>
  <div>
    <div style="margin-top: 2%">
      Order info
    </div>
    <div  v-for="(item, index) in orderItems" :key="index" class="order-item">

      <el-row >
        <el-col :span="8">
          <el-image
            style="width: 100px; height: 100px"
            :src="item.imgUrl"
            fit="scale-down"></el-image>
        </el-col>
        <el-col :span="10">
          <div>

            {{item.productName}}
          </div>
          <div>
            x {{item.productNum}}
          </div>
        </el-col>
        <el-col :span="6">
          $ {{item.priceAUD * item.productNum}}
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Order",
  props: {
    orderItems: []
  },
  created() {
    console.log("order items:", this.orderItems)
  }
}
</script>

<style scoped>
.order-item{
  margin-top: 2%;
  padding: 5px;
  border-radius: 10px;
  border: 1px #eceef5 solid;
}
</style>
