<!--
  Created by Lingjun Meng on 21/7/2022
-->
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-top: 2%" :label-position="'top'">
      <el-breadcrumb-item @click.native="changeTab('cart')">Cart</el-breadcrumb-item>
      <el-breadcrumb-item>Check Out</el-breadcrumb-item>
    </el-breadcrumb>
    <order :order-items="productList">

    </order>
    <div class="payment-info-form">

      <el-form ref="contactInfo"
               :model="contactInfo"
               label-width="80px"
               :label-position="'top'">
        <el-form-item label="Email"
          prop="email"
          :rules="[{ required: true, message: 'Please input the email address.', trigger: 'blur' }]">
<!--      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }-->
          <el-input v-model="contactInfo.email"></el-input>
        </el-form-item>
        <el-form-item label="First Name"
                      :rules="[{ required: true, message: 'Please input first name.', trigger: 'blur' }]">
          <el-input v-model="contactInfo.firstName"></el-input>
        </el-form-item>
        <el-form-item label="Last Name"
                      :rules="[{ required: true, message: 'Please input last name.', trigger: 'blur' }]">
          <el-input v-model="contactInfo.lastName"></el-input>
        </el-form-item>
        <el-form-item label="Address"
                      :rules="[{ required: true, message: 'Please input address.', trigger: 'blur' }]">
          <el-input v-model="contactInfo.address"></el-input>
        </el-form-item>
        <el-form-item label="City"
                      :rules="[{ required: true, message: 'Please input city.', trigger: 'blur' }]">
          <el-input v-model="contactInfo.city"></el-input>
        </el-form-item>
        <el-form-item label="State/Province"
                      :rules="[{ required: true, message: 'Please input state.', trigger: 'blur' }]">
          <el-input v-model="contactInfo.state"></el-input>
        </el-form-item>
        <el-form-item label="PostCode"
                      :rules="[{ required: true, message: 'Please input post code.', trigger: 'blur' }]">
          <el-input v-model="contactInfo.postCode"></el-input>
        </el-form-item>
        <el-form-item label="Phone"
                      :rules="[{ required: true, message: 'Please input phone number.', trigger: 'blur' }]">
          <el-input v-model="contactInfo.phone"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="margin-left: 25%" @click="processToShipping()">
            Continue to shipping
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Order from "@/views/cart/components/Order";

export default {
  name: "checkout",
  components: {Order},
  data() {
    return {
      active: 0,
      productList: [],
      contactInfo: {
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        postCode: '',
        phone: ''
      },
      orderItems: []
    };
  },
  props: {
    orderProducts: []
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    changeTab(tab) {
      this.$emit('changeTab', tab)
    },
    processToShipping() {
      this.$emit('setContactInfo', this.contactInfo)
      this.$emit('changeTab', 'shipping')
    },
    rendOrderItems() {

    }
  },
  created() {
    this.productList = this.orderProducts
    // console.log(this.productList)
  }
}
</script>

<style scoped>
.payment-info-form {
  margin-top: 5%;
  text-align: left;
}

/deep/ .el-form--label-top .el-form-item__label {
  padding: 0 !important;

}

/deep/ .el-input__inner {
  border-radius: 0 !important;
}
</style>
