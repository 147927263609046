/**
 * Created by Lingjun Meng on 31/5/2022
 */
const zh_cn = {
  message: {
    hello : "你好"
  },
  home: {
    title1: "给你更好的生活！"
  }
}
export default zh_cn
