<!--
  Created by Lingjun Meng on 22/7/2022
-->
<template>
  <!-- Mobile 端 -->
  <div>
    <div class="hidden-sm-and-up">
      <el-image
        style="min-height: 200px"
        src="/static/img/banner_faq.png"
        fit="cover"
      ></el-image>
      <div style="text-align: left">
        <h3>Does KINNS milk have same protein level as regular milk?</h3>
        <p>
          No, KINNS milk sources milk only from the "Jersey cow" which naturally
          produces higher levels of protein than regular milk and milk that only
          contains A2 protein.
        </p>
        <h3>Does KINNS milk have same calcium level as regular milk?</h3>
        <p>
          No, KINNS milk sources milk only from the "Jersey cow" which naturally
          produces higher levels of protein than regular milk and milk that only
          contains A2 protein.
        </p>
        <h3>Does KINNS milk taste the same as regular milk?</h3>
        <p>Many people say they free the taste of KINNS MILK</p>
        <h3>Is KINNS produced in Australia?</h3>
        <p>Yes, KINNS products are 100% manufacture in Melbourne, Australia</p>
      </div>
    </div>

    <!-- PC 端 -->

    <br/>
          <br/>
          <br/>
    <div class="hidden-xs-only">
      <el-row style="margin-top: 10px">
        <!-- Section 1 -->

        <!-- Title -->
        <h1 style="color:#7A675D; margin-left:-8%">It’s a Jersey</h1><br>

        <!-- left column -->
        <el-col :span="11" style="border-right: 1.5px inset #96B5CE; padding-right:2%;">
          <div>
            <!-- 1.Image -->
            <div>
              <div>
                <span class="cow_img">
                  <!-- Cow Image Title -->
                </span>
                <!-- Cow Image -->
                <el-image :src="src" style="width:30%; margin-left:10%">
                  <!-- Cow Image Lazy Load -->
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
              </div>
            </div>
          </div>

          <!-- 2.Text -->
          <div style="margin-left:10%; text-align: left">
            <h2 style="color:#96B5CE">Why call them Jersey Cows?</h2>

            <p style="color:#7A675D;"><b>Because the Jersey cattle breed originated on the British Channel island of Jersey, and was the first recorded as a separate breed in the 1700’s.
            </b></p><br>

            <h2 style="color:#96B5CE">How can you tell if it’s a Jersey cow?</h2>

            <p style="color:#7A675D;"><b>Jersey cows comes in all shades of brown!</b></p>
          </div>
        </el-col>

        <!-- right column -->
        <el-col :span="13">
          <div>
            <!-- 3.Text -->
            <div style="margin-left:10%; text-align: left">

              <br>
              <h2 style="color:#96B5CE">What are they known for?</h2>

              <p style="color:#7A675D;"><b>Their high butterfact content in their milk and their heat tolerance and superior grazing ability.</b></p><br>

              <h2 style="color:#96B5CE">Where can you find Jersey cows?</h2>

              <p style="color:#7A675D;"><b>In India, South America, USA, Australia and New Zealand.</b></p><br></br>

              <h2 style="color:#96B5CE">Difference between Jersey cows and other breeds</h2>

              <p style="color:#7A675D;"><b>Jersey Milk contains 18% more protein and 20% more calcium than Milk from other breeds of Cow!</b></p>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- Section 2 -->
      <div>
        <br><h1 style="color:#7A675D; margin-left:-8%">Now for the fun facts!</h1><br>



        <!-- 2.Image -->
        <el-image class="overleap_img" :src="fun_fact"></el-image>

        <!-- 1.Text  -->
        <div class="section_2_content" style="margin: 25px; display: flex; justify-content: center;">
          <div style="width:60%; background-color: #A5C2D8; border-radius: 120px; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); text-align: left; justify-content: center; padding:2% 10% 2% 10%;">
            <!-- Fun Fact Content -->
            <li style="color:#fff;"><b>Their high butterfact content in their milk and their heat tolerance and superior grazing ability.</b></li><br>
            <li style="color:#fff;"><b>Jersey Cows produce 200,000 glasses of milk on average (during their lifetime).</b></li><br>
            <li style="color:#fff;"><b>With over 800 breeds of cattle, Jersey cows are the second largest breed!<br>
              &emsp;&nbsp;On average, A Jersey cow can produce around 140 servings of milk in one day.<br>
              &emsp;&nbsp;On average, a Jersey cow drinks 130 litres of water in one day.</b></li><br>
            <li style="color:#fff;"><b>Jersey Cows produce the most milk at 4°C degrees.</b></li><br>
            <li style="color:#fff;"><b>Jersey Cows share 80% of their genes with humans.</b></li><br>
            <li style="color:#fff;"><b>If you count the number of rings on a cows horn, it tells you their age.</b></li><br>
            <li style="color:#fff;"><b>Jersey Cows can walk up stairs but can’t get back down because their knees don’t bend the right way.</b></li><br>
            <li style="color:#fff;"><b>Jersey cows can smell up to 9 kilometres away.</b></li><br>
            <li style="color:#fff;"><b>Milk is the best cure after eating spicy foods because milk contain protein casein; which cleanses burning taste buds.</b></li><br>
            <li style="color:#fff;"><b>Most of the time, Jersey cows sleeping standing up!</b></li><br>
            <li style="color:#fff;"><b>Jersey cows don’t sweat, so prefer the winter and like to live in the shade to keep cool in the summer.</b></li><br>
            <li style="color:#fff;"><b>Jersey cows hear high and lower frequencies than humans.</b></li><br>          

        </div>
      </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        
      </div>
    </div>
  </div>
</template>

<script>
import jersey_url from "@/assets/img/jersey.png";
import fun_fact_url from "@/assets/img/fun_facts.png";
export default {
  name: "index",
  data() {
    return {
      src: [jersey_url],
      fun_fact: [fun_fact_url],
    };
  },
};
</script>

<style scoped>

.overleap_img {
  position: absolute;
  left: 70%;
  top: 73%;
  z-index: 1;
  width: 25%
}

.section_2_content{
  position: relative;
}
</style>
