/**
 * Created by Lingjun Meng on 12/8/2022
 */
import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/sso/login',
    method: 'post',
    params: data
  })
}


export function register(data) {
  return request({
    url: '/sso/register',
    method: 'post',
    params: data
  })
}

