/**
 * Created by Lingjun Meng on 26/6/2022
 */
import Vue from 'vue'
import Router from 'vue-router'
import Checkout from "@/views/cart/components/checkout";

import Home from "@/views/home";
import Product from "@/views/product/productList";
import Cart from "@/views/cart";
import ProductDetail from "@/views/product/ProductDetail";
import Payment from "@/views/cart/components/payment";
import Faq from "@/views/faq";
import ContactUs from "@/views/contactUs/contactUs";
import Order from "@/views/order";
import ProductBatches from "@/views/product/productBatches";
import BatchesDetail from "@/views/product/BatchesDetail";

Vue.use(Router)


export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
  },
  {
    path: '/productBatches',
    name: 'ProductBatches',
    component: ProductBatches,
  },
  {
    path: '/productBatches/lactoferrin:batchId',
    name: 'BatchesDetail',
    component: BatchesDetail,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: Faq,
  },
  {
    path: '/contactUs',
    name: 'Contact',
    component: ContactUs,
  },
  {
    path: '/productDetail/:id',
    name: 'ProductDetail',
    component: ProductDetail
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
  },
  {
    path: '/order',
    name: 'Order',
    component: Order,
  },
]
const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})
const router = createRouter()

export default router
