<!--
  Created by Lingjun Meng on 28/6/2022
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="cartVisible"
    width="100%"
    :show-close="false"
    :before-close="closeCart"
    :fullscreen="true"
    :destroy-on-close="true"
    center>

    <div v-if="tab==='cart'">
      <el-table
        ref="multipleTable"
        :data="productList"
        tooltip-effect="dark"
        style="width: 100%"
        header-align="center"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="45">
        </el-table-column>
        <el-table-column
          label=""
          width="60">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px; margin-left: -50%"
              :src="scope.row.imgUrl"
              fit="scale-down"></el-image>
          </template>
        </el-table-column>
        <el-table-column

          label="Name"
          width="120">
          <template slot-scope="scope">
            <p>{{ scope.row.productName }}</p>
            <p>${{ scope.row.priceAUD }}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="Subtotal"
          width="120">
          <template slot-scope="scope">
            <p>$ {{ (scope.row.productNum * scope.row.priceAUD).toFixed(2) }}</p>
            <el-input-number size="mini"
                             style="width: 80%;margin-top: -5%"
                             v-model="scope.row.productNum"
                             controls-position="right"
                             @change="changeProductNum($event, scope.row.productId)"
                             :min="1" :max="10"></el-input-number>
            <p style="height: 2%" @click="removeItem(scope.row.productId)"><a>Remove</a></p>
          </template>
        </el-table-column>

      </el-table>
      <span slot="footer" class="dialog-footer">
    <el-button @click="closeCart()">Back</el-button>
    <el-button type="primary" @click="checkOut()">Check Out</el-button>
          </span>
    </div>
    <checkout
      @changeTab="changeTab"
      @setContactInfo = "setContactInfo"
      :order-products = "multipleSelection"
      v-if="tab==='checkout'"></checkout>

    <shipping
      @changeTab="changeTab"
      :contact-info="contactInfo"
      :order-products = "multipleSelection"
      v-if="tab==='shipping'"></shipping>
    <payment
      @changeTab="changeTab"
      :contact-info="contactInfo"
      :order-products = "multipleSelection"
      v-if="tab==='payment'"></payment>
<!--    <el-table-->
<!--      ref="multipleTable"-->
<!--      :data="productList"-->
<!--      tooltip-effect="dark"-->
<!--      style="width: 100%"-->
<!--      header-align="center"-->
<!--      @selection-change="handleSelectionChange"-->
<!--    >-->
<!--      <el-table-column type="selection" width="45"> </el-table-column>-->
<!--      <el-table-column label="" width="60">-->
<!--        <template slot-scope="scope">-->
<!--          <el-image-->
<!--            style="width: 60px; height: 60px; margin-left: -50%"-->
<!--            :src="scope.row.imgUrl"-->
<!--            fit="scale-down"-->
<!--          ></el-image>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="Name" width="120">-->
<!--        <template slot-scope="scope">-->
<!--          <p>{{ scope.row.productName }}</p>-->
<!--          <p>${{ scope.row.priceAUD }}</p>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="Subtotal" width="120">-->
<!--        <template slot-scope="scope">-->
<!--          <p>$ {{ scope.row.productNum * scope.row.priceAUD }}</p>-->
<!--          <el-input-number-->
<!--            size="mini"-->
<!--            style="width: 80%; margin-top: -5%"-->
<!--            v-model="scope.row.productNum"-->
<!--            controls-position="right"-->
<!--            @change="changeProductNum($event, scope.row.productId)"-->
<!--            :min="1"-->
<!--            :max="10"-->
<!--          ></el-input-number>-->
<!--          <p style="height: 2%" @click="removeItem(scope.row.productId)">-->
<!--            <a>Remove</a>-->
<!--          </p>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--    </el-table>-->
<!--    <span slot="footer" class="dialog-footer">-->
<!--      <el-button @click="closeCart()">Back</el-button>-->
<!--      <el-button type="primary" @click="closeCart()">Check Out</el-button>-->
<!--    </span>-->
  </el-dialog>
</template>

<script>
import CartItem from "@/views/cart/components/CartItem";

import {getCart, updateCart} from "@/utils/store";
import Checkout from "@/views/cart/components/checkout";
import Shipping from "@/views/cart/components/shipping";
import Payment from "@/views/cart/components/payment";

export default {
  name: "index",
  components: {Payment, Shipping, Checkout, CartItem},

  props: {
    cartVisible: false,
  },
  data() {
    return {
      dialogTableVisible: false,
      dialogFormVisible: false,
      title: 'Your Cart',
      tab: 'cart',
      input: 5,
      cartList: [],
      formLabelWidth: "120px",
      productList: [],
      contactInfo: {
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        postCode: '',
        phone: ''
      },
      shippingInfo: {

      },

      products: [
        {
          productId: 0,
          imgUrl: "/static/img/uht_200_01.jpeg",
          productName: "UHT 200",
          imageSize: "80%",
          priceAUD: 22.95,
          productNum: 10,
        },
        {
          productId: 1,
          imgUrl: "/static/img/uht_1000_01.jpeg",
          productName: "UHT 1000",
          imageSize: "80%",
          priceAUD: 24.95,
        },
        {
          productId: 2,
          imgUrl: "/static/img/full_cream_milk_powder_01.png",
          productName: "Full Cream Milk powder",
          imageSize: "80%",
          priceAUD: 22.95
        },
        {
          productId: 3,
          imgUrl: "/static/img/lactoferrin_milk_powder_01.jpeg",
          productName: "Lactoferrin Milk Powder",
          imageSize: "80%",
          priceAUD: 28.80
        },
        {
          productId: 4,
          imgUrl: "/static/img/growth_care_01.png",
          productName: "Growth Care",
          imageSize: "80%",
          priceAUD: 19.95,
        },
      ],
      multipleSelection: [],
    };
  },
  methods: {
    closeCart() {
      this.$emit('viewCart')
    },
    checkOut() {
      console.log(this.multipleSelection)
      this.tab = 'checkout'
      this.$emit('changeTab')

      // this.$emit('checkOutCart', this.multipleSelection)
      // this.$emit('viewCart')
    },
    changeItemNum(param) {
      updateCart(param.productId, param.productNum.toString())
      this.cartList[param.productId] = param.productNum


    },
    changeTab(tab){
      this.tab = tab
      if(tab === 'cart'){
        this.title = 'Your Cart'
      } else if(tab === 'checkout'){
        this.title = 'Check Out'
      }

    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection)
    },
    changeProductNum(val, productId) {
      console.log('this.productList', this.productList)
      console.log('this.productId', productId)

      this.productList.map((item, i) => {

        if (item.productId === productId) {
          item.productNum = val
        }
      });
      updateCart(productId, val);
      this.$emit("updateProductListItem", { productId: productId, num: val });
    },
    removeItem(productId) {
      updateCart(productId, 0)

      this.productList.map((val, i) => {

        if (val.productId === productId) {
          this.productList.splice(i, 1)
        }
      });
      this.$emit("updateProductListItem", { productId: productId, num: 0 });
    },
    renderCart() {

      this.productList = []


      this.cartList = getCart().split("-").map(Number);
      for (let i = 0; i < this.cartList.length; i++) {
        if (this.cartList[i] !== 0) {
          this.productList.push({
            productId: this.products[i].productId,
            imgUrl: this.products[i].imgUrl,
            productName: this.products[i].productName,
            imageSize: this.products[i].imageSize,
            priceAUD: this.products[i].priceAUD,
            productNum: this.cartList[i],
          });
        }
      }

      console.log(this.productList)
    },
    setContactInfo(param){
      this.contactInfo = param
    }


  },
  created() {
    this.renderCart();
  },
};
</script>

<style scoped>
.cart-item-num {
  max-height: 50px;
}

/deep/ .el-input-number .is-controls-right .el-input__inner {
  width: 10% !important;

}
.dialog-footer{
  margin-left: 25%;
  margin-top: 10%;

}

/deep/ .el-input__inner{
  border-radius: 0 !important;
}
</style>
