/**
 * Created by Lingjun Meng on 31/5/2022
 */
/**
 * 英文
 */
const en_us = {
  message: {
    hello : "Hello"
  },
  home: {
    title1: "Helping People Enjoy a Better Life!"
  }

}
export default en_us
