<!--
  Created by Lingjun Meng on 3/10/2022
-->
<template>
  <div>
    <!--    手机端    -->
    <div class="hidden-sm-and-up">
      <div>
        <div class="product-batches-title" style="padding-left: 25px">
          <h1>Product Tracking</h1>
        </div>
        <div class="product-batches-timeline">
          <el-timeline :reverse="true">
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :timestamp="activity.timestamp"
            >
              <div>
                <p @click="jumpToBatchDetail()">{{ activity.content }}</p>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>

    <!--    电脑端    -->
    <div>
      <div class="hidden-xs-only">
        <!-- product batches image -->
        <div class="demo-image__lazy">
          <!-- click image scroll to product batches tracking contents -->
          <el-image
            v-for="url in urls"
            :key="url"
            :src="url"
            lazy
            onclick="window.scrollTo(0, 800);"
          ></el-image>
        </div>
        <!-- Show More Button -->
        <svg class="arrows" onclick="window.scrollTo(0, 900);">
          <path class="a1" d="M0 0 L30 32 L60 0"></path>
          <path class="a2" d="M0 20 L30 52 L60 20"></path>
          <path class="a3" d="M0 40 L30 72 L60 40"></path>
        </svg>

        <!-- Product Batches Contents -->
        <div class="center_box" style="margin-top: -5%">
          <!-- Title -->
          <h1 style="margin: 0 auto">Product Tracking</h1>
          <br />
          <br />
          <!-- Content List -->
          <div style="margin-left: -50px">
            <div>
              <el-timeline :reverse="true">
                <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  :timestamp="activity.timestamp"
                >
                  <div>
                    <p @click="jumpToBatchDetail(activity)">{{ activity.content }}</p>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    </div>
  </div>
</template>

<script>
import production_url from "@/assets/img/production.jpeg";
export default {
  name: "productBatches",
  data() {
    return {
      urls: [production_url], // production image
      activeNames: ["1"], // show more
      reverse: true,
      activities: [
        {
          content: "Kinns Dairy Lactoferrin Milk Powder",
          timestamp: "14-12-2020",
          batchId: '122020',
        },
        {
          content: "Kinns Dairy Lactoferrin Milk Powder",
          timestamp: "20-12-2020",
          batchId: '032023',
        },
        {
          content: "Kinns Dairy Lactoferrin Milk Powder",
          timestamp: "06-03-2023",
          batchId: '032023',
        },
      ],
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    jumpToBatchDetail(activity) {
      this.$router.push("/productBatches/lactoferrin" + activity.batchId);
    },
  },
};
</script>

<style scoped>
.product-batches-timeline {
  padding: 10px 0 0 0;
  text-align: left;
}

.product-batches-title {
  text-align: left;
}

.center_box {
  width: 300px;
  height: 300px;
  margin-left: 100px;
  margin-top: 100px;
  margin: 100px auto;
  padding-bottom: 15.625rem;
}

/* show more button */

.arrows {
  /* margin: auto 51.5% auto 48.5%; */
  position: relative;
  bottom: 25px;
  left: 120px;
  right: auto;
}

.arrows path {
  stroke: #5c558f;
  fill: transparent;
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}
</style>
