<!--
  Created by Lingjun Meng on 22/7/2022
-->
<template>

  <div>
    <!-- 电脑端 -->
    <!-- simply add padding-left to solve box responsive problem on PC  -->
    <div class="hidden-xs-only">
      <div class="contact-us-tittle" style="padding-left:5%;">
        <h1>
          Contact us
        </h1>
      </div>

      <div class="contact-us-general-enquiries" style="padding-left:10%; width:80%">
        <h3>
          Department contacts
        </h3>
        <div class="department-contact-block">
          <h4>General enquiries</h4>

          <p>Call the number below between <span style="color:#5B5B5B">Monday - Friday (9:00am to 5:00pm)</span></p>
          <p class="cotact_detail">Or <a href="Mailto:trade@kinns.com.au">Email</a> us and we will help you connect
            with the right team member promptly.
          </p>

          <p class="cotact_detail">
            &emsp;&emsp;&emsp;Tel:  <a :href="'tel:' +610396148888">+(61) 03 9614 8888</a>
          </p>
          <p class="cotact_detail">
            &emsp;&emsp;&emsp;Email: <a href="Mailto:trade@kinns.com.au">trade@kinns.com.au</a>
          </p>
        </div>
      </div>

      <div class="contact-us-general-enquiries" style="padding-left:10%; padding-bottom: 35px; width:80%">
        <h3>
          Office Locations
        </h3>
        <div class="department-contact-block">
          <p class="cotact_detail" style="text-align:center;">
            <b>Melbourne: </b>
            <a href="https://www.google.com/maps/search/PO+BOX+655,+Doncaster+VIC+3108,+AUSTRALIA/@-37.7864255,145.1248885,17z/data=!3m1!4b1">PO BOX 655, Doncaster VIC 3108, AUSTRALIA</a>
          </p>
          <br>

          <p align="center"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25226.392214713273!2d145.10498153025023!3d-37.78303140449489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6472a6aa3a563%3A0x1c045678462e37d0!2sDoncaster%20VIC%203108%2C%20Australia!5e0!3m2!1sen!2shk!4v1666341383721!5m2!1sen!2shk" width="90%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></p>
        </div>
      </div>
    </div>

    <!-- 手机端 -->
    <div class="hidden-sm-and-up">
      <div class="contact-us-tittle">

        <h1>
          Contact us
        </h1>
      </div>

      <div class="contact-us-general-enquiries">
        <h2>
          Department contacts
        </h2>
        <div class="department-contact-block">
          <h4>General enquiries</h4>
          <p>Call the number below between 9:00am to 5:00pm, Monday-Friday, or email us and we will help you connect
            with
            the right team member promptly.
          </p>
          <p>
            Tel:  <a :href="'tel:' +610396148888">+(61) 03 9614 8888</a>
          </p>
          <p>
            Email: <a href="Mailto:trade@kinns.com.au">trade@kinns.com.au</a>
          </p>

        </div>
      </div>


      <div class="contact-us-general-enquiries">
        <h2>
          Office Locations
        </h2>
        <div class="department-contact-block">
          <h4>Melbourne</h4>
          PO BOX 655,
          <br>
          Doncaster VIC 3108
          <br>
          AUSTRALIA

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>
.contact-us-tittle {
  font-family: sans-serif;
  float: left;
  text-align: left;
  width: 100%;
}

.contact-us-general-enquiries {
  width: 100%;
  float: left;
  text-align: left;

}

.contact-us-general-enquiries .department-contact-block {
  padding: 0 20px 20px 20px;
  border: #e5e5e5 1px solid;
  border-radius: 8px;
  color:#606266;
}

.cotact_detail a:link{
  color: #578DE8;
  background-color: transparent;  
}
.cotact_detail a:visited{
  color: #578DE8;
  background-color: transparent;  
}
</style>
