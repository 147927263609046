/**
 * Created by Lingjun Meng on 29/6/2022
 */
import Cookies from 'js-cookie'

const TokenKey = 'accessToken'
const cart = "cart"
const UserInfo = 'userInfo'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserInfo() {
  return Cookies.get(UserInfo)
}

export function setUserInfo(userInfo) {
  return Cookies.set(UserInfo, userInfo)
}

export function removeUserInfo() {
  return Cookies.remove(UserInfo)
}


export function initCart() {

  if(getCart() === null || getCart() === '' || getCart() === undefined){
    return Cookies.set(cart, [0, 0, 0, 0, 0].join("-"))
  }
}


export function getCart() {
  return Cookies.get(cart)
}

export function updateCart(index, num) {
  let cartList = getCart().split("-")
  cartList[index] = num
  return Cookies.set(cart, cartList.join("-"))
}

export function clearCart() {
  return Cookies.remove(cart)
}
