<!--
  Created by Lingjun Meng on 19/7/2022
-->
<template>
  <div>
    <div>
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="margin-top: 2%;">
        <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item>Orders</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div>

    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      active: 0
    };
  },
}
</script>

<style scoped>

</style>
