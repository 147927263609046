/**
 * Created by Lingjun Meng on 10/7/2022
 */
import {getAllProduct} from "@/api/product";

// const products = getAllProduct()
// export default products

const products = [{
  productId: 0,
  bannerImg: "",
  imgUrl: "/static/img/uht_200_00.png",
  productName: 'UHT 200',
  imageSize: "80%",
  priceAUD: 22.95,
  detailInfo: "KINNS DAIRY full cream UHT milk is formulated for  Ages above 1 years old. Except under medical advice.<br>" +
    "<br>" +
    "KINNS DAIRY full cream UHT milk is 100% Australian natural milk, sourced from happy grass-fed cows.<br>" +
    "<br>" +
    "KINNS DAIRY full cream UHT milk is a natural source of protein and calcium with a great creamy taste.<br>" +
    "<br>" +
    "Perfect for daily dairy nutrition.<br>",
  serveInfo: "Servings per pack: 4 <br>" +
    "Serving Size: 250ml",
  nrvInfo: "",
  healthInfo: [
    {"items": "Energy", "qtyPS": "510kJ", "qtyP100": "255kJ"},
    {"items": "Protein", "qtyPS": "6.8g", "qtyP100": "3.4g"},
    {"items": "Fat - Total", "qtyPS": "6.6g", "qtyP100": "3.3g"},
    {"items": "  - Saturated", "qtyPS": "5.0g", "qtyP100": "2.5g"},
    {"items": "Carbohydrate", "qtyPS": "9.2g", "qtyP100": "4.6g"},
    {"items": "  - Sugars(Lactose)", "qtyPS": "9.2g", "qtyP100": "4.6g"},
    {"items": "Sodium", "qtyPS": "86mg", "qtyP100": "43mg"},
    {"items": "Calcium", "qtyPS": "240mg", "qtyP100": "120mg"},

  ]
},
  {
    productId: 1,
    bannerImg: "",
    imgUrl: "/static/img/uht_1000_00.png",
    productName: 'UHT 1000',
    imageSize: "80%",
    priceAUD: 24.95,
    detailInfo: "KINNS DAIRY full cream UHT milk is formulated for  Ages above 1 years old. Except under medical advice.<br>" +
      "<br>" +
      "KINNS DAIRY full cream UHT milk is 100% Australian natural milk, sourced from happy grass-fed cows.<br>" +
      "<br>" +
      "KINNS DAIRY full cream UHT milk is a natural source of protein and calcium with a great creamy taste.<br>" +
      "<br>" +
      "Perfect for daily dairy nutrition.<br>",
    serveInfo: "Servings per package: 1<br>Serving Size: 200ml",
    nrvInfo: "",
    healthInfo: [
      {"items": "Energy", "qtyPS": "638kJ", "qtyP100": "255kJ"},
      {"items": "Protein", "qtyPS": "8.5g", "qtyP100": "3.4g"},
      {"items": "Fat - Total", "qtyPS": "8.3g", "qtyP100": "3.3g"},
      {"items": "  - Saturated", "qtyPS": "6.3g", "qtyP100": "2.5g"},
      {"items": "Carbohydrate", "qtyPS": "11.5g", "qtyP100": "4.6g"},
      {"items": "  - Sugars(Lactose)", "qtyPS": "11.5g", "qtyP100": "4.6g"},
      {"items": "Sodium", "qtyPS": "108mg", "qtyP100": "43mg"},
      {"items": "Calcium", "qtyPS": "300mg", "qtyP100": "120mg"},
    ]
  }, {
    productId: 2,
    bannerImg: "/static/img/banner_full_cream_milk_powder_00.png",
    imgUrl: "/static/img/full_cream_milk_powder_00.png",
    productName: 'Full Cream Milk powder',
    imageSize: "80%",
    priceAUD: 22.95,
    detailInfo: "KINNS DAIRY full cream milk powder is formulated for all ages.<br>" +
      "<br>" +
      "KINNS DAIRY full cream milk powder is made in Australia using 100% Australian milk.<br>" +
      "<br>" +
      "KINNS DAIRY full cream milk powder:<br>" +
      "Good source of protein<br>" +
      "High in calcium<br>" +
      "Rich in vitamin A&D<br>" +
      "Good source of lactase<br>" +
      "No preservatives<br>",
    serveInfo: "",
    nrvInfo: "*Nutrient Reference Values (NRV) are based on an average adult diet of 8,700 KJ. Your daity intake may be higher or lower.",
    healthInfo: [
      {"items": "Energy", "qtyPS": "769 kJ", "qtyP100": "2,139 kJ", "NRV": "89%"},
      {"items": "Protein", "qtyPS": "8.3 g", "qtyP100": "23.6 g", "NRV": "13.85%"},
      {"items": "Fat", "qtyPS": "10.2 g", "qtyP100": "29.0 g", "NRV": "16.9%"},
      {"items": "Saturated Fat", "qtyPS": "6.7 g", "qtyP100": "19.1 g", "NRV": ""},
      {"items": "Carbohydrates", "qtyPS": "13.7 g", "qtyP100": "39.1 g", "NRV": "4.6%"},
      {"items": "Sodium", "qtyPS": "119 mg", "qtyP100": "340 mg", "NRV": "6.0%"},
      {"items": "Vitmin A", "qtyPS": "287 ug", "qtyP100": "820 ug", "NRV": "35.9%"},
      {"items": "Vitamin D", "qtyPS": "4 ug", "qtyP100": "10 ug", "NRV": "70.0%"},
      {"items": "Calcium", "qtyPS": "245 mg", "qtyP100": "700 mg", "NRV": "30.6%"},
    ]
  }, {
    productId: 3,
    bannerImg: "",
    imgUrl: "/static/img/lactoferrin_milk_powder_00.png",
    productName: 'Lactoferrin Milk Powder',
    imageSize: "80%",
    priceAUD: 28.80,
    detailInfo: "KINNS+ lactoferrin milk powder formula is formulated for all ages<br>" +
      "<br>" +
      "KINNS+ lactoferrin milk powder is made in Australia with premium ingredients. <br>" +
      "<br>" +
      "KINNS+ nutrient rich formula helps to;<br>" +
      "Boost the immune system<br>" +
      "Maintain a healthy digestive system <br>" +
      "Improve brain development and function<br>",
    serveInfo: "Number of per Serve: 2 Sachets <br>" +
      "Serving Size: 2g of powder in 50ml of water",
    nrvInfo: "",
    healthInfo: [
      {"items": "Energy", "qtyPS": "31 kJ", "qtyP100": "1540 kJ"},
      {"items": "", "qtyPS": "7 kcal", "qtyP100": "368 kcal"},
      {"items": "Protein", "qtyPS": "0.7 g", "qtyP100": "33.0 g"},
      {"items": "Lactoferrin", "qtyPS": "2 mg", "qtyP100": "100 mg"},
      {"items": "Fat", "qtyPS": "0 g", "qtyP100": "0.8 g"},
      {"items": "  - Saturated", "qtyPS": "0 g", "qtyP100": "0.8 g"},
      {"items": "Carbohydrate", "qtyPS": "11 g", "qtyP100": "55.4 g"},
      {"items": "  - Sugars", "qtyPS": "1.1 g", "qtyP100": "55.4 g"},
      {"items": "Dietary Fibre (FOS)", "qtyPS": "0.02 g", "qtyP100": "1.0 g"},
      {"items": "Sodium", "qtyPS": "8 mg", "qtyP100": "415 mg"},
    ]
  }, {
    productId: 4,
    bannerImg: "",
    imgUrl: "/static/img/growth_care_00.png",
    productName: 'Growth Care',
    imageSize: "80%",
    priceAUD: 19.95,
    detailInfo: "KINNS+ growth care supplement is formulated for children and adults.<br>" +
      "<br>" +
      "KINNS+ growth care supplement is made in Australia with premium ingredients.<br>" +
      "<br>" +
      "KINNS+ growth care supplement is formulated with;<br>" +
      "<br>" +
      "Calcium - is vital for healthy teeth and bones. It is also important for the health and functioning of nerves and muscle tissue.<br>" +
      "<br>" +
      "Colostrum- contains powerful antibodies such as IgG that support the immune system.<br>" +
      "<br>" +
      "Lutein esters - suppress inflammation, defends against free radicals and oxidative stress. Protects eyesight sharpness, contrast and protects against eye disease<br>" +
      "<br>" +
      "DHA - is an omega-3 fatty acid essential for brain development in pregnancy and early childhood. It also improves heart health, vision and reduces inflammatory responses.<br>",
    serveInfo: "",
    nrvInfo: "*Nutrient Reference Values (NRV) are based on an average adult diet of 8,700 KJ. Your daity intake may be higher or lower.",
    healthInfo: [
      {"items": "Energy", "qtyP100": "1650 kJ", "NRV": "20%"},
      {"items": "Protein", "qtyP100": "22.1 g", "NRV": "37%"},
      {"items": "Fat", "qtyP100": "6.6 g", "NRV": "11%"},
      {"items": "Carbohydrate", "qtyP100": "60.6 g", "NRV": "20%"},
      {"items": "Sodium", "qtyP100": "157 mg", "NRV": "8%"},
      {"items": "Calcium", "qtyP100": "924 mg", "NRV": "116%"},

    ]
  }]

export default products
